<template>
  <v-layout max-width max-height class="flex-column white pt-0">
    <!-- <div class="flex-y-center flex-justify-between pa-2 white mb-3">
      <div>
        <v-btn dark small @click="addShow" color="#00bea9" depressed min-width='30' style="height:32px; padding:0;">
          <v-icon size="20">mdi-plus</v-icon>
        </v-btn>

      </div>
      <div class=" flex-y-center">

      </div>

    </div> -->
    <div class="">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4 header-grey"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
      >
        <!-- <template v-slot:item="{expand,item}"> -->
        <template v-slot:item.active="{item}">
          <a class=" mr-2" @click="showEdit(item)">设置命名格式</a>
          <a class=" mr-2" @click="showEdit(item)">设置存储路径</a>
        </template>
      </v-data-table>
    </div>
    <!-- 新增 编辑 组织角色 -->
    <v-dialog v-model="dialog.show" width="600">
      <v-card>
        <v-card-title class="font-weight-bold">{{ dialog.id?'编辑节点':'新增节点' }}</v-card-title>
        <v-divider />
        <v-card-text class=" py-5">
          <div class="flex-1 flex-column overflow-auto mt-7 px-12">
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>节点名称：</div>
              <input
                v-model="dialog.data.username"
                class="flex-1 default-input py-1 subtitle-1"
                placeholder="节点名称"
                type="text"
              >
            </div>
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>颜色：</div>
              <v-menu
                :close-on-content-click="false"
                bottom
                max-height="300"
                offset-overflow
                transition="slide-y-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <div class="default-input" style="height:32px;" color="primary" :style="{background:dialog.data.color||'#fff'}" v-on="on" />
                </template>
                <v-color-picker
                  v-model="dialog.data.color"
                  class="ma-2"
                  dot-size="30"
                />
              </v-menu>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" class depressed @click="dialog.show = false">取消</v-btn>
              <v-btn
                width="80"
                class="ml-3"
                color="primary"
                depressed
                @click.stop="submitAdd()"
              >确定</v-btn>

            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 新增 编辑 组织角色 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      headers: [
        { text: '报告名称', value: 'name' },
        { text: '命名格式', value: 'color' },
        { text: '存储路径', value: 'color' },
        { text: '操作', value: 'active' }
      ],
      list: [
        { name: '报告名称报告名称', color: '系统管理员', id: '1' },
        { color: '质量总监', id: '2' }
      ],
      dialog: {
        show: false,
        type: '',
        data: {

        }
      }
    }
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      this.$http.get(this.$api.userList, { ...this.search }).then(res => {
        if (res.code === 0) {
          this.list = res.result.records || []
        }
      })
    },
    // 编辑弹窗
    showEdit(item) {
      console.log(item)
      this.dialog.show = true
      this.dialog.data = { ...item }
    },
    addShow() {
      this.dialog.show = true
    },
    delShow(id) {
      this.$http.delete(this.$api.userDeleteBatch, { ids: id }).then(res => {
        if (res.code === 0) {
          this.getList()
        }
      })
    },
    submitAdd() {}
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:100px;
    color: #777;
}
</style>
